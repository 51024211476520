import { Component, Signal } from '@angular/core'
import { toSignal } from '@angular/core/rxjs-interop'
import { ActivatedRouteSnapshot, ChildActivationEnd, Router } from '@angular/router'
import { filter, map } from 'rxjs'

/**
 * Wrapper for every page requiring a user to be authorized
 *
 * @export
 * @class LoggedInPage
 */
@Component({
  selector: 'app-logged-in-page',
  templateUrl: './logged-in.page.html',
  styleUrls: ['./logged-in.page.scss'],
  host: {
    class: 'd-flex flex-column h-100vh',
  },
})
export class LoggedInPage {
  protected layout: Signal<string>

  constructor(private router: Router) {
    this.layout = toSignal(
      this.router.events.pipe(
        filter(event => event instanceof ChildActivationEnd),
        map((event: ChildActivationEnd) => this.getLastAvailableLayout(event.snapshot)),
        map(layout => layout || 'legacy')
      )
    )
  }

  private getLastAvailableLayout(snapshot: ActivatedRouteSnapshot): string | null {
    let currentSnapshot: ActivatedRouteSnapshot | null = snapshot
    let lastLayout: string | null = null

    while (currentSnapshot) {
      if (currentSnapshot.data?.layout) {
        lastLayout = currentSnapshot.data.layout as string
      }
      currentSnapshot = currentSnapshot.firstChild
    }

    return lastLayout
  }
}
