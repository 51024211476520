<app-header-menu class="w-100 sticky-top"></app-header-menu>
<app-breadcrumbs></app-breadcrumbs>
@if (layout() === 'v3') {
  <main class="overflow-auto">
    <router-outlet></router-outlet>
  </main>
} @else {
  <main class="overflow-auto">
    <div class="d-flex flex-column flex-grow-1 bg-alt-white p-4">
      <router-outlet></router-outlet>
    </div>
  </main>
}
