import { Clipboard } from '@angular/cdk/clipboard'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { NgbModal } from '@ng-bootstrap/ng-bootstrap'
import { ConfirmModalComponent } from 'app/shared/components/confirm-modal/confirm-modal.component'
import { BreadcrumbsService } from 'app/shared/services/breadcrumbs.service'
import { ToastService } from 'app/shared/services/toast.service'
import { readableFormGroupErrors } from 'app/shared/utils/form-group-errors'
import { parseGraphQLError } from 'app/shared/utils/parse-gql-error'
import { ClientCredential, ClientCredentialFailureInfo, CreateClientCredentialInput } from 'generated/graphql'
import { omit, pickBy } from 'lodash'
import { combineLatest, Subject, Subscription } from 'rxjs'
import { filter, map, switchMap, tap } from 'rxjs/operators'
import { ClientCredentialsService } from '../client-credentials.service'

const DEFAULT_PASSWORD = 'fake_password'

// Default values if no failure info exists for this credential
const DEFAULT_FAILURE_INFO = {
  consecutiveFailureCount: 'No data',
  disabledAt: 'No data',
  disabledBy: 'No data',
} as const

/**
 * Page to create and edit existing client credential
 *
 * @export
 * @class ClientCredentialEditPage
 * @implements {OnInit}
 * @implements {OnDestroy}
 */
@Component({
  selector: 'app-client-credential-edit',
  templateUrl: './client-credential-edit.page.html',
  host: { class: 'h-100' },
})
export class ClientCredentialEditPage implements OnInit, OnDestroy {
  isLoading: boolean = true
  clientCredential: ClientCredential
  failureInfo: ClientCredentialFailureInfo | typeof DEFAULT_FAILURE_INFO
  routerSub$: Subscription
  saving: boolean = false
  passwordFormControl = new UntypedFormControl(null, Validators.required)
  form = new UntypedFormGroup({
    type: new UntypedFormControl(null, Validators.required),
    username: new UntypedFormControl(null),
    mfa: new UntypedFormControl(null),
    sessionStorage: new UntypedFormControl(null),
    email: new UntypedFormControl(null, Validators.email),
    password: this.passwordFormControl,
    disabled: new UntypedFormControl(false),
    concurrency: new UntypedFormControl(null),
    securityQuestions: new UntypedFormArray([]),
  })
  triggerRoute$ = new Subject<boolean>()
  didGetPassword = false
  newCredentialId: string

  constructor(
    private toast: ToastService,
    private clientCredentialsService: ClientCredentialsService,
    private router: Router,
    private route: ActivatedRoute,
    private clipboard: Clipboard,
    private breadcrumbService: BreadcrumbsService,
    private modal: NgbModal,
  ) {
    this.routerSub$ = combineLatest([router.events, this.triggerRoute$])
      .pipe(
        filter(([event, trigger]) => event instanceof NavigationEnd || trigger),
        map(() => this.route.snapshot.paramMap.get('clientCredentialId')),
        tap((clientCredentialId) => {
          if (clientCredentialId === 'new') {
            this.clientCredential = null
            this.form.reset()
            this.isLoading = false
          }
        }),
        filter((clientCredentialId) => clientCredentialId != 'new'),
        switchMap((clientCredentialId) => {
          let credential$ = this.clientCredentialsService.getClientCredential(clientCredentialId)
          let sessionStorage$ = this.clientCredentialsService.getClientCredentialSessionStorage(clientCredentialId)
          let failureInfo$ = this.clientCredentialsService.getClientCredentialFailureInfo(clientCredentialId)

          this.passwordFormControl.setValue(DEFAULT_PASSWORD)

          return combineLatest([credential$, sessionStorage$, failureInfo$]).pipe(
            map(([credential, sessionStorage, failureInfo]) => {
              this.clientCredential = credential?.data?.clientCredential
              this.failureInfo = failureInfo?.data?.clientCredentialFailureInfo ?? DEFAULT_FAILURE_INFO
              this.setFormAndBreadcrumb()
              this.form.get('sessionStorage').setValue(sessionStorage?.data?.getClientCredentialSessionStorage)
              this.isLoading = credential?.loading
            }),
          )
        }),
      )
      .subscribe()
  }

  setDidGetPassword() {
    this.didGetPassword = true
  }

  ngOnInit(): void {
    this.triggerRoute$.next(true)
  }

  ngOnDestroy(): void {
    this.routerSub$.unsubscribe()
  }

  /**
   * Determines if the credential failure date is valid for parsing with DatePipe
   * @returns {boolean}
   */
  isCredentialFailureDateValid(): boolean {
    const credDate = new Date(this.failureInfo?.disabledAt)
    const isValidDate = !isNaN(credDate.getTime())
    return isValidDate
  }

  onPasswordCopyClick(): void {
    try {
      const copySuccess = this.clipboard.copy(this.form.get('password').value)
      if (copySuccess) {
        this.toast.success('Password copied to clipboard!')
      } else {
        throw new Error('Error occurred copying password to clipboard')
      }
    } catch (err) {
      this.toast.error('Issue occurred copying password to clipboard')
    }
  }

  onUsernameCopyClick(): void {
    try {
      const copySuccess = this.clipboard.copy(this.form.get('username').value)
      if (copySuccess) {
        this.toast.success('Username copied to clipboard!')
      } else {
        throw new Error('Error occurred copying username to clipboard')
      }
    } catch (err) {
      this.toast.error('Issue occurred copying username to clipboard')
    }
  }

  onEmailCopyClick(): void {
    try {
      const copySuccess = this.clipboard.copy(this.form.get('email').value)
      if (copySuccess) {
        this.toast.success('Email copied to clipboard!')
      } else {
        throw new Error('Error occurred copying email to clipboard')
      }
    } catch (err) {
      this.toast.error('Issue occurred copying email to clipboard')
    }
  }

  /**
   * Populate form for existing client credential
   *
   * @memberof ClientCredentialEditPage
   */
  setFormAndBreadcrumb(): void {
    // let lastCrumb = this.breadcrumbService.breadcrumbs[this.breadcrumbService.breadcrumbs.length - 1]
    // lastCrumb.label = this.clientCredential?.type || 'New Client Credential'

    this.breadcrumbService.setPageTitle('Admin - Client Credentials - ' + this.clientCredential?.type || 'New')

    if (this.clientCredential?.id) {
      this.form.patchValue({
        type: this.clientCredential?.type,
        username: this.clientCredential?.username,
        email: this.clientCredential?.email,
        disabled: this.clientCredential?.disabled,
        locked: this.clientCredential?.locked,
        mfa: this.clientCredential?.mfa,
        concurrency: this.clientCredential?.concurrency,
      })
    }
    if (this.clientCredential?.securityQuestions?.length) {
      let securityQuestions = this.form.get('securityQuestions') as UntypedFormArray
      this.clientCredential?.securityQuestions?.forEach((question) => {
        securityQuestions.push(
          new UntypedFormGroup({
            question: new UntypedFormControl(question.question),
            answer: new UntypedFormControl(question.answer),
          }),
        )
      })
    }
  }

  /**
   * Save changes to, or create new, client credential
   *
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialEditPage
   */
  async saveClientCredential(): Promise<void> {
    this.saving = true
    if (this.form.invalid) {
      this.toast.error(
        'Please confirm all required fields are filled in before saving',
        JSON.stringify(readableFormGroupErrors(this.form)),
      )
    } else {
      let password = this.passwordFormControl.value
      let sessionStorage = this.form.get('sessionStorage').value
      let form = omit(
        pickBy(this.form.value, (value) => value != null),
        ['password', 'sessionStorage'],
      ) as CreateClientCredentialInput
      // Update clientCredential if one with this ID already exists
      try {
        if (this.clientCredential?.id) {
          this.clientCredential = await this.clientCredentialsService.updateClientCredential(
            this.clientCredential.id,
            form,
          )
          // Update Session Storage & Check and update Password
          try {
            await this.clientCredentialsService.updateSessionStorage(this.clientCredential.id, sessionStorage)
          } catch(e) {
            // Notify that New cred was created but sessionStorage value was invalid
            this.toast.success('Client credential updated successfully')
            this.toast.error('Unable to save Cookie/Session Storage data')
            this.router.navigate([`admin/client-credentials/${this.clientCredential?.id ?? ''}`])
            throw e
          }
          if (this.didGetPassword) {
            await this.clientCredentialsService.updateClientCredentialPassword(this.clientCredential.id, password)
          }
          this.setFormAndBreadcrumb()
          // Create new clientCredential
        } else {
          let result = await this.clientCredentialsService.createClientCredential(form)
          if (result.id) {
            this.newCredentialId = result.id
            // Update Session Storage & Password
            try {
              await this.clientCredentialsService.updateSessionStorage(result.id, sessionStorage)
            } catch(e) {
              // Notify that New cred was created but sessionStorage value was invalid
              this.toast.success('Client credential successfully saved')
              this.toast.error('Unable to save Cookie/Session Storage data')
              this.router.navigate([`admin/client-credentials/${this.newCredentialId ?? ''}`])
              throw e
            }
            await this.clientCredentialsService.updateClientCredentialPassword(result.id, password)
          }
        }
        this.toast.success('Client credential successfully saved')
        this.router.navigate(['admin/client-credentials'])
      } catch (e) {
        this.toast.error(parseGraphQLError(e, 'Could not save client credential'), JSON.stringify(e))
      }
    }
    this.saving = false
  }

  /**
   * Launch modal to get confirmation for deleting the current client credential
   *
   * @memberof ClientCredentialEditPage
   */
  confirmDelete(): void {
    const modalRef = this.modal.open(ConfirmModalComponent, { centered: true })
    modalRef.componentInstance.title = 'Delete Client Credential?'
    modalRef.componentInstance.body = 'Once this client credential is deleted its data cannot be recovered'
    modalRef.componentInstance.yes = 'Delete client credential'
    modalRef.componentInstance.yesClass = 'btn-danger'

    modalRef.result.then(
      (closed) => {
        this.deleteClientCredential()
      },
      (dismissed) => {},
    )
  }

  /**
   * Delete the current client credential
   *
   * @return {*}  {Promise<void>}
   * @memberof ClientCredentialEditPage
   */
  async deleteClientCredential(): Promise<void> {
    try {
      await this.clientCredentialsService.deleteClientCredential(this.clientCredential.id)
      this.toast.success('Client credential successfully deleted')
      this.router.navigate(['admin/client-credentials'])
    } catch (e) {
      this.toast.error(parseGraphQLError(e, 'Could not delete client credential'), JSON.stringify(e))
    }
  }

  /**
   * Adding a security question object to the currently existing security questions
   *
   * @memberof ClientCredentialEditPage
   */
  addSecurityQuestion(): void {
    let questions = this.form.get('securityQuestions') as UntypedFormArray
    questions.push(new UntypedFormGroup({ question: new UntypedFormControl(''), answer: new UntypedFormControl('') }))
  }

  /**
   * Removing a security question object from the currently existing security questions
   *
   * @param {number} index
   * @memberof ClientCredentialEditPage
   */
  removeSecurityQuestion(index: number): void {
    let questions = this.form.get('securityQuestions') as UntypedFormArray
    questions.removeAt(index)
  }

  /**
   * Getting security questions controls for list
   *
   * @readonly
   * @type {AbstractControl[]}
   * @memberof ClientCredentialEditPage
   */
  get securityQuestions(): AbstractControl[] {
    return (this.form.get('securityQuestions') as UntypedFormArray).controls
  }
}
