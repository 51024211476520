<div class="d-flex flex-column h-100">
  <div class="d-flex flex-column flex-basis-0 flex-grow-1 pt-2 scrolling-sidebar">
    <div *ngIf="!isLoading;else loadingBlock" class="card my-1 p-0 border-0 bg-transparent">
      <div class="card-header border-0 bg-primary text-white">
        {{clientCredential?.id ? 'Edit Client Credential Details' : 'Add Client Credential'}}
      </div>
      <div class="row no-gutters h-100">
        <div class="mt-2 bg-white col-lg-8 col-xl-6">
          <div class="card-body shadow d-flex flex-column flex-basis-0 flex-grow-1">
            <form
              [formGroup]="form"
              (ngSubmit)="saveClientCredential()"
              class="d-flex flex-column flex-basis-0 flex-grow-1 px-4"
            >
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="type" class="required">Type</label>
                    <input
                      type="text"
                      class="form-control shadow-sm"
                      formControlName="type"
                      id="type"
                      [appFcBsValidation]="form.get('type')"
                    />
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="username">Username</label>
                    <div class="d-flex align-items-center">
                      <input type="text" class="form-control shadow-sm w-90" formControlName="username" id="username" />
                      <button
                        id="copy-username-button"
                        type="button"
                        class="btn btn-outline-primary flex-shrink-1 d-flex align-items-center ml-2 p-3"
                        (click)="onUsernameCopyClick()"
                        ngbTooltip="Copy username to clipboard"
                        container="body"
                      >
                        <i id="copyClickIcon" class="fa fa-clone"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="email">Email</label>
                    <div class="d-flex align-items-center">
                      <input
                        type="email"
                        class="form-control shadow-sm w-90"
                        formControlName="email"
                        id="email"
                        [appFcBsValidation]="form.get('email')"
                      />
                      <button
                        id="copy-email-button"
                        type="button"
                        class="btn btn-outline-primary flex-shrink-1 d-flex align-items-center ml-2 p-3"
                        (click)="onEmailCopyClick()"
                        ngbTooltip="Copy email to clipboard"
                        container="body"
                      >
                        <i id="copyClickIcon" class="fa fa-clone"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="mfa">MFA Code</label>
                    <input
                      type="mfa"
                      class="form-control shadow-sm"
                      formControlName="mfa"
                      id="mfa"
                      [appFcBsValidation]="form.get('mfa')"
                    />
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <label for="password" class="required">Password</label>
                  <div class="d-flex align-items-start">
                    <app-client-credential-password-input
                      class="w-100"
                      [passwordFormControl]="passwordFormControl"
                      [clientCredentialId]="clientCredential ? clientCredential.id : null"
                      (didGetPassword)="setDidGetPassword()"
                    ></app-client-credential-password-input>
                    <button
                      id="copy-password-button"
                      type="button"
                      class="btn btn-outline-primary flex-shrink-1 d-flex align-items-center ml-2 p-3"
                      (click)="onPasswordCopyClick()"
                      ngbTooltip="Copy password to clipboard"
                      container="body"
                    >
                      <i id="copyClickIcon" class="fa fa-clone"></i>
                    </button>
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="concurrency">Max. Concurrent Bots</label>
                    <input
                      type="number"
                      class="form-control shadow-sm"
                      formControlName="concurrency"
                      id="concurrency"
                      [appFcBsValidation]="form.get('concurrency')"
                    />
                  </div>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <div class="form-group">
                    <label for="sessionStorage">Session Storage</label>
                    <textarea
                      class="form-control shadow-sm"
                      formControlName="sessionStorage"
                      id="sessionStorage"
                      [appFcBsValidation]="form.get('sessionStorage')"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col" formArrayName="securityQuestions">
                  <div class="d-flex justify-content-between">
                    <div>
                      <label for="securityQuestions" class="mb-3" *ngIf="form.get('securityQuestions')?.value?.length">
                        Security Questions
                      </label>
                    </div>
                    <div>
                      <button class="btn btn-primary" type="button" (click)="addSecurityQuestion()">
                        <i class="fa fa-plus mr-3"></i>Add Security Question
                      </button>
                    </div>
                  </div>
                  <div
                    [attr.id]="'securityQuestion_' + i"
                    class="row"
                    *ngFor="let question of securityQuestions; index as i"
                    [formGroupName]="i"
                  >
                    <div class="col-1 d-flex justify-content-center align-items-center">
                      <button
                        type="button"
                        class="btn btn-link p-0"
                        data-t="remove-question-button"
                        ngbTooltip="Remove question from credential"
                        container="body"
                        (click)="removeSecurityQuestion(i)"
                      >
                        <i class="fa fa-fw fa-2x fa-trash text-danger"></i>
                      </button>
                    </div>
                    <div class="col-11">
                      <div class="row mx-0 my-3">
                        <label for="question" class="col-xl-3">Question</label>
                        <input
                          type="text"
                          class="form-control shadow-sm col-xl-9"
                          id="question"
                          formControlName="question"
                        />
                      </div>
                      <div class="row mx-0 my-3">
                        <label for="answer" class="col-xl-3">Answer</label>
                        <input
                          type="text"
                          class="form-control shadow-sm col-xl-9"
                          id="answer"
                          formControlName="answer"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div class="custom-control custom-switch text-nowrap mb-2 pl-5">
                    <input class="custom-control-input" type="checkbox" id="disabled" formControlName="disabled" />
                    <label class="custom-control-label ml-4" for="disabled">Disabled</label>
                  </div>
                </div>
              </div>
              <div class="row mt-5">
                <div class="col d-flex flex-nowrap justify-content-end">
                  <div>
                    <button
                      type="button"
                      class="btn btn-secondary mr-1"
                      data-t="delete-client-cred-button"
                      (click)="confirmDelete()"
                    >
                      <i class="fa fa-trash mr-3"></i>
                      Delete
                    </button>
                    <a class="btn btn-cancel mx-1" routerLink="/admin/client-credentials"> Cancel </a>
                    <button type="submit" class="btn btn-primary pl-4 pr-5 ml-1" data-t="save-client-button">
                      <i class="fa fa-floppy-o mr-2"></i>
                      Save
                      <app-loading *ngIf="saving" class="pl-2"></app-loading>
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div
          class="mt-2 px-2 bg-white col-lg-4 col-xl-6 d-flex flex-column flex-basis-0 flex-grow-1"
          *ngIf="clientCredential?.email"
        >
          <div class="card border-0 shadow d-flex flex-column flex-basis-0 flex-grow-1">
            <div class="card-body d-flex flex-column flex-grow-1 flex-basis-lg-0">
              <app-view-emails class="scrolling-sidebar" [username]="clientCredential.email"></app-view-emails>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="clientCredential?.id">
        <div class="card-header border-0 bg-primary text-white mt-5">
          Credential Failure Info
        </div>
        <div class="row no-gutters h-100">
          <div class="mt-2 bg-white col-lg-8 col-xl-6">
            <div class="d-flex flex-column flex-basis-0 flex-grow-1 px-4">
              <div class="row my-3">
                <div class="col">
                  <label for="disabled-by">Disabled by:</label>
                  <span 
                    id="disabled-by" 
                    class="ml-3"
                  >
                  {{
                    this.failureInfo.disabledBy === 'bot'
                      ? 'Automatically disabled'
                      : this.failureInfo.disabledBy
                  }}
                  </span>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <label for="disabled-at">Disabled at:</label>
                  <span 
                    id="disabled-at" 
                    class="ml-3"
                  >
                  {{
                    this.isCredentialFailureDateValid() === false
                      ? this.failureInfo.disabledAt
                      : this.failureInfo.disabledAt | date: 'EEEE, MMM dd YYYY, hh:mm OOOO'
                  }}
                  </span>
                </div>
              </div>
              <div class="row my-3">
                <div class="col">
                  <label for="consecutive-failures">Consecutive failures:</label>
                  <span id="consecutive-failures" class="ml-3">{{this.failureInfo.consecutiveFailureCount}}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #loadingBlock class="h-100 d-flex align-items-center justify-content-center">
  <app-loading innerClass="fa-3x" outerClass="text-brand-light-blue"></app-loading>
</ng-template>
