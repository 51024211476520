<div class="w-100 bg-white" ngbDropdown placement="bottom-left">
  <!-- Dropdown Toggle -->
  <button
    class="btn d-flex justify-content-between rotate-on-open align-items-center border-alt-light-gray text-alt-medium-gray w-100"
    [ngClass]="{ 'btn-md btn-light rounded border-1 no-dropdown-arrow': iconButton }"
    aria-label="Filter Options"
    ngbDropdownToggle
  >
    <div class="overflow-hidden" *ngIf="!iconButton; else icon_button">
      {{ placeholderText }}
    </div>
    <ng-template #icon_button>
      <i class="fa fa-filter" *ngIf="iconButton"></i>
      <span class="ml-1">{{ placeholderText }}</span>
    </ng-template>
  </button>

  <div ngbDropdownMenu class="checkbox-filter-container minw-15vw maxw-100vw pt-0">
    <div class="pt-0 pb-3">
      <div class="multiselect-typeahead-batch-controls position-sticky top-0 pt-2 bg-white">
        <!-- Typeahead search -->
        <fieldset class="px-3">
          <div class="input-group d-flex position-relative">
            <label for="searchField" class="sr-only">Search</label>
            <input
              appTrimmedInput
              type="text"
              class="multiselect-typeahead-search-input form-control text-alt-medium-gray rounded-left border-alt-light-gray"
              aria-label="Search Bar"
              placeholder="Search"
              [formControl]="searchFormControl"
              (input)="search$.next($any($event).target.value)"
              #searchInput
            />
            <i class="fa fa-search position-absolute text-alt-medium-gray"></i>
            <span class="in-input-icon d-flex align-items-center justify-content-center">
              <button
                *ngIf="searchInput.value.length && !(searching$ | async)"
                class="btn bg-transparent"
                type="button"
                (click)="resetSearch()"
              >
                <i class="fa fa-times fa-fw text-brand-dark-gray"></i>
              </button>
              <app-loading *ngIf="searching$ | async"></app-loading>
            </span>
          </div>
        </fieldset>

        <div class="dropdown-divider mx-3"></div>

        <!-- Select All / None -->
        <div class="d-flex justify-content-between px-3">
          <button data-id="filter-dropdown-select-all" type="button" class="btn btn-link p-0" (click)="toggleAll(true)">
            <span>Select all</span>
          </button>

          <button data-id="filter-dropdown-clear-all" type="button" class="btn btn-link p-0" (click)="toggleAll(false)">
            <span>Select none</span>
          </button>
        </div>

        <div class="dropdown-divider mx-3"></div>
      </div>

      <!-- Checkboxes -->
      <div>
        <ng-container
          *ngTemplateOutlet="
            filterCheckboxList;
            context: {
              items: selectedFilters$ | async
            }
          "
        ></ng-container>
        <ng-container
          *ngTemplateOutlet="
            filterCheckboxList;
            context: {
              items: unselectedFilters$ | async
            }
          "
        ></ng-container>
      </div>

      <ng-template #filterCheckboxList let-items="items">
        @for (item of items; track item.data.id) {
          <div
            class="custom-control custom-checkbox d-flex justify-content-start py-2"
            data-t="multiselect-typeahead-item"
          >
            <input
              [attr.data-t]="'filter-' + item.data.id"
              type="checkbox"
              class="custom-control-input"
              [attr.id]="inputPrefix + '-' + item.data.id"
              [formControl]="item.control"
            />
            <label
              class="custom-control-label text-alt-medium-gray pl-2 mx-3 maxw-40vw"
              [for]="inputPrefix + '-' + item.data.id"
            >
              <ng-container [ngTemplateOutlet]="filterLabel" [ngTemplateOutletContext]="{ item: item }"></ng-container>
            </label>
          </div>
        }
      </ng-template>
    </div>
  </div>
</div>
