import { Injectable } from '@angular/core'
import { ApolloQueryResult } from '@apollo/client'
import { Apollo } from 'apollo-angular'
import {
  CheckInClientCredential,
  CheckOutClientCredential,
  CreateClientCredential,
  DeleteClientCredential,
  GetClientCredential,
  GetClientCredentialFailureInfo,
  GetClientCredentialPassword,
  SearchClientCredentials,
  UpdateClientCredential,
  UpdateClientCredentialPassword,
  UpdateSessionStorage,
  GetClientCredentialSessionStorage,
} from 'app/admin/client-credentials/client-credentials.gql'
import { UsersService } from 'app/shared/services/users.service'
import { debug } from 'app/shared/utils/debug'
import {
  ClientCredential,
  ClientCredentialFailureInfo,
  ClientCredentialsList,
  CreateClientCredentialInput,
  ListResponseMetaData,
  MutationCheckInClientCredentialsArgs,
  MutationCheckOutClientCredentialsArgs,
  MutationCreateClientCredentialArgs,
  MutationDeleteClientCredentialArgs,
  MutationUpdateClientCredentialArgs,
  MutationUpdateSessionStorageArgs,
  QueryClientCredentialArgs,
  QueryClientCredentialFailureInfoArgs,
  QueryClientCredentialsArgs,
  QueryGetClientCredentialPasswordArgs,
  QueryGetClientCredentialSessionStorageArgs,
  UpdateClientCredentialInput,
  UpdateClientCredentialPasswordMutationVariables,
  User,
} from 'generated/graphql'
import { uniq } from 'lodash'
import { Observable } from 'rxjs'
import { tap } from 'rxjs/operators'

/**
 * Handle Client Credential operations
 *
 * @export
 * @class ClientCredentialsService
 */
@Injectable({
  providedIn: 'root',
})
export class ClientCredentialsService {
  private meta: ListResponseMetaData
  searchVars: QueryClientCredentialsArgs

  constructor(private apollo: Apollo, private usersService: UsersService) {}

  /**
   * Helper to get current request's pagination and search data
   *
   * @return {*}  {ListResponseMetaData}
   * @memberof ClientCredentialsService
   */
  getMeta(): ListResponseMetaData {
    return this.meta
  }

  /**
   * Retrieve failure info for a client credential
   * 
   * @param {string} clientCredentialId
   * @return {*}  {Observable<ApolloQueryResult<{ clientCredentialFailureInfo: ClientCredentialFailureInfo }>>}
   * @memberof ClientCredentialsService
   */
  getClientCredentialFailureInfo(
    clientCredentialId: string,
  ): Observable<ApolloQueryResult<{ clientCredentialFailureInfo: ClientCredentialFailureInfo }>> {
    return this.apollo.watchQuery<
      { clientCredentialFailureInfo: ClientCredentialFailureInfo },
      QueryClientCredentialFailureInfoArgs
    >({
      query: GetClientCredentialFailureInfo,
      variables: { clientCredentialId },
    }).valueChanges
  }

  /**
   * Retrieve single client credential
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ clientCredential: ClientCredential }>>}
   * @memberof ClientCredentialsService
   */
  getClientCredential(id: string): Observable<ApolloQueryResult<{ clientCredential: ClientCredential }>> {
    return this.apollo.watchQuery<{ clientCredential: ClientCredential }, QueryClientCredentialArgs>({
      query: GetClientCredential,
      variables: {
        id,
      },
    }).valueChanges
  }

  /**
   * Retrieve list of client credentials
   *
   * @param {QueryClientCredentialsArgs} [variables]
   * @return {*}  {Observable<ApolloQueryResult<{ clientCredentials: ClientCredentialsList }>>}
   * @memberof ClientCredentialsService
   */
  searchClientCredentials(
    variables?: QueryClientCredentialsArgs,
  ): Observable<ApolloQueryResult<{ clientCredentials: ClientCredentialsList }>> {
    debug('client-credential-service', 'search for clientCredential', variables?.search)
    this.searchVars = variables
    return this.apollo
      .watchQuery<{ clientCredentials: ClientCredentialsList }, QueryClientCredentialsArgs>({
        query: SearchClientCredentials,
        variables,
        fetchPolicy: 'cache-and-network',
      })
      .valueChanges.pipe(
        tap((result) => {
          this.meta = result?.data?.clientCredentials?.meta
        }),
      )
  }

  /**
   * Retrieve password for a client credential
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ getClientCredentialPassword: string }>>}
   * @memberof ClientCredentialsService
   */
  getClientCredentialPassword(id: string): Observable<ApolloQueryResult<{ getClientCredentialPassword: string }>> {
    return this.apollo.watchQuery<{ getClientCredentialPassword: string }, QueryGetClientCredentialPasswordArgs>({
      query: GetClientCredentialPassword,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  /**
   * Update password for a client credential
   *
   * @param {string} id
   * @param {string} password
   * @return {*}  {Promise<string>}
   * @memberof ClientCredentialsService
   */
  async updateClientCredentialPassword(id: string, password: string): Promise<string> {
    let result = await this.apollo
      .mutate<{ updateClientCredentialPassword: string }, UpdateClientCredentialPasswordMutationVariables>({
        mutation: UpdateClientCredentialPassword,
        variables: { id, password },
      })
      .toPromise()

    return result.data?.updateClientCredentialPassword
  }

  /**
   * Create new client credential
   *
   * @param {CreateClientCredentialInput} data
   * @return {*}  {Promise<ClientCredential>}
   * @memberof ClientCredentialsService
   */
  async createClientCredential(data: CreateClientCredentialInput): Promise<ClientCredential> {
    let result = await this.apollo
      .mutate<{ createClientCredential: ClientCredential }, MutationCreateClientCredentialArgs>({
        mutation: CreateClientCredential,
        variables: { data },
      })
      .toPromise()

    return result.data?.createClientCredential
  }

  /**
   * Update existing client credential
   *
   * @param {string} id
   * @param {UpdateClientCredentialInput} data
   * @return {*}  {Promise<ClientCredential>}
   * @memberof ClientCredentialsService
   */
  async updateClientCredential(id: string, data: UpdateClientCredentialInput): Promise<ClientCredential> {
    let result = await this.apollo
      .mutate<{ updateClientCredential: ClientCredential }, MutationUpdateClientCredentialArgs>({
        mutation: UpdateClientCredential,
        variables: { id, data },
      })
      .toPromise()

    return result.data?.updateClientCredential
  }

  /**
   * Delete client credential
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof ClientCredentialsService
   */
  async deleteClientCredential(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ deleteClientCredential: boolean }, MutationDeleteClientCredentialArgs>({
        mutation: DeleteClientCredential,
        variables: {
          id: id,
        },
      })
      .toPromise()

    return result.data?.deleteClientCredential
  }

  /**
   * Checks out the credential with the current user's ID
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof ClientCredentialsService
   */
  async checkOutClientCredentials(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ checkOutClientCredentials: boolean }, MutationCheckOutClientCredentialsArgs>({
        mutation: CheckOutClientCredential,
        variables: { id },
      })
      .toPromise()

    return result.data?.checkOutClientCredentials
  }

  /**
   * Checks in the credential to be used again
   *
   * @param {string} id
   * @return {*}  {Promise<boolean>}
   * @memberof ClientCredentialsService
   */
  async checkInClientCredentials(id: string): Promise<boolean> {
    let result = await this.apollo
      .mutate<{ checkInClientCredentials: boolean }, MutationCheckInClientCredentialsArgs>({
        mutation: CheckInClientCredential,
        variables: { id },
      })
      .toPromise()

    return result.data?.checkInClientCredentials
  }

  /**
   * Retrieve session storage for a client credential as string
   *
   * @param {string} id
   * @return {*}  {Observable<ApolloQueryResult<{ getClientCredentialSessionStorage: string }>>}
   * @memberof ClientCredentialsService
   */
  getClientCredentialSessionStorage(
    id: string,
  ): Observable<ApolloQueryResult<{ getClientCredentialSessionStorage: string }>> {
    return this.apollo.watchQuery<
      { getClientCredentialSessionStorage: string },
      QueryGetClientCredentialSessionStorageArgs
    >({
      query: GetClientCredentialSessionStorage,
      variables: { id },
      fetchPolicy: 'network-only',
    }).valueChanges
  }

  /**
   * Updates Session Storage Cookie data for this credential
   *
   * @param {string} id
   * @param {string | null | undefined} rawSessionStorage
   * @return {*}  {Promise<ClientCredential>}
   * @memberof ClientCredentialsService
   */
  async updateSessionStorage(id: string, rawSessionStorage: string | null | undefined): Promise<boolean> {
    // null check. If no form value, will update DB with NULL
    if (!rawSessionStorage) rawSessionStorage = ''

    // Validate that we have a valid JSON string (or it's empty to null the value) in theform field
    const validateRawSessionStorage = async (sessionStorageValue: string) =>  {
      if (sessionStorageValue.length === 0) return true // If field is empty, that's OK and we'll null it out
      JSON.parse(sessionStorageValue)
      return true
    }

    // If we have valid data, run the update
    if (validateRawSessionStorage(rawSessionStorage)) {
      let result = await this.apollo
        .mutate<{ updateSessionStorage: boolean }, MutationUpdateSessionStorageArgs>({
          mutation: UpdateSessionStorage,
          variables: { id, rawSessionStorage },
        })
        .toPromise()

      return result.data?.updateSessionStorage
    }
  }

  /**
   * Saturates a list of client credentials with extra details (ie. checkedOutBy user)
   *
   * @param {ClientCredential[]} credentials
   * @return {*}  {Promise<{ credential: ClientCredential; checkedOutBy: User }[]>}
   * @memberof ClientCredentialsService
   */
  async saturateCredentialList(
    credentials: ClientCredential[],
  ): Promise<{ credential: ClientCredential; checkedOutBy: User }[]> {
    let userIds = []
    let details = credentials.map((credential) => {
      if (credential.checkedOutByUserId) {
        userIds.push(credential.checkedOutByUserId)
      }
      return {
        credential,
        checkedOutBy: null,
      }
    })
    try {
      if (userIds.length) {
        // Fetch all users in a single request using the getUsers method
        let users = await this.usersService.getUsers(undefined, uniq(userIds))
        let usersMap = new Map(users.map((user) => [user.id, user]))

        // Map users to their credentials
        details = credentials.map((credential) => {
          let checkedOutBy: User
          if (credential.checkedOutByUserId) {
            checkedOutBy = usersMap.get(credential.checkedOutByUserId)
          }
          return { credential, checkedOutBy }
        })
      }
    } catch (e) {
      debug('client-credentials-service', JSON.stringify(e))
    }
    return details
  }
}
